import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import './App.css';
import Description from './components/description';
import Header from './components/header';
import Teammates from './components/teammates';
import { db } from './firebase'; // firebase.js dosyasından veritabanı nesnesini içe aktar

function App() {
  const [visitorCount, setVisitorCount] = useState(null);

  useEffect(() => {
    const dbRef = db.ref("visitorCount");

    // Increment the counter in the database
    dbRef.transaction((currentCount) => {
      return (currentCount || 0) + 1;
    });

    // Listen for changes and update the state
    dbRef.on("value", (snapshot) => {
      const count = snapshot.val();
      setVisitorCount(count);
    });

  }, []);

  return (
      <div className="App">
          <Helmet>
              <meta charSet="utf-8" />
              <title>Pyderall</title>
              <link rel="canonical" href="http://pyderall.dev"/>
          </Helmet>
        <Header visitorCount={visitorCount} />
        <Description />
        <Teammates />
      </div>
  );
}

export default App;
