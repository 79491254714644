import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyDoy1zv6YFM5NyIa9JhVxYYdMkQu8zTlqA",
    authDomain: "visitorcounter-c17b5.firebaseapp.com",
    databaseURL: "https://visitorcounter-c17b5-default-rtdb.firebaseio.com",
    projectId: "visitorcounter-c17b5",
    storageBucket: "visitorcounter-c17b5.appspot.com",
    messagingSenderId: "58527683712",
    appId: "1:58527683712:web:079ca4066774d2f8198265",
    measurementId: "G-SB4MSQY8FR",
  };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.database();

export { db };

