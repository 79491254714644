import React from 'react';

function description() {
    return (
        <section id="description">
            <div>
                <h1>About <span>Me</span></h1>
                <p>
                    I am a seasoned full-stack engineer, possessing more than 3 years of
                    industry experience.<br />
                    My focus on delivering innovative and efficient solutions has played
                    an instrumental role in meeting my clients' requirements and driving
                    results.
                    <br />As a quick learner and detail-oriented software engineer, I have
                    an unwavering passion for delivering exceptional outcomes.<br />
                    I strive to constantly augment my skills and stay abreast of the
                    latest technological advancements and industry trends.
                </p>
                <div className="actions">
                    <button className="btn-apply">Resume</button>
                </div>
            </div>
        </section>
    );
}

export default description;
