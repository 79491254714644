import React from 'react';

function teammates() {
    return (
        <div id="teammates">
        <div id="box-1" class="box">
          <img src="img/team_member_1.svg" alt="" />
          <h1>Pyderall</h1>
          <h2>Full Stack Developer</h2>
          <div id="medias">
            <a href="https://github.com/Agueria"
              ><img
                class="social-media"
                src="img/github-mark-white.svg"
                alt=""
            /></a>
            <a
              href="https://www.linkedin.com/in/cem-berk-%C3%A7ak%C4%B1r-96a942220/"
              ><img
                class="social-media"
                src="img/linkedin-svgrepo-com.svg"
                alt=""
            /></a>
          </div>
        </div>
      </div>
    );
}

export default teammates;
