import React from 'react';

function Header({visitorCount}) {
    return (
        <header>
            <div className="logo">
                <h1 className="text-white">Pyderall</h1>
            </div>
            <img className="background-light" src={process.env.PUBLIC_URL + '/img/light.svg'} alt="Background Light" />
            <div className="actions">
                <div id="visitorCounter" className="visitor-counter">Visitor Counter: {visitorCount}</div>
            </div>
        </header>
    );
}

export default Header;
